import * as React from 'react'
import SEO from '@/components/seo'
import Button from '../components/button'

const IndexPage = () => {
  return (
    <main className="h-full flex flex-col items-center justify-center p-8">
      <SEO title="Home" isFront={true} />
      <p className="font-marker text-3xl sm:text-5xl tracking-widest max-w-5xl text-center">
        Are you ready to join the greatest fraternity in the world?
      </p>
      <Button
        as="a"
        target="_blank"
        rel="noopener noreferrer"
        href="https://form.jotform.com/83127281962157"
        className="text-xl font-marker tracking-widest mt-16"
      >
        Sign Me Up
      </Button>
    </main>
  )
}

export default IndexPage
